/**
 * Initialize any TinyMCE editors on the page if TinyMCE is available. If
 * TinyMCE is not available, this returns `false` and does nothing.
 * @returns {boolean} Whether TinyMCE initialization was completed.
 */
function initializeTinyMce() {
  if (typeof TinyMCERails !== 'undefined') {
    TinyMCERails.configuration.default = {
      selector: "textarea.tinymce",
      cache_suffix: "?v=6.2.0",
      plugins: "image,link,table,wordcount"
    };
    TinyMCERails.initialize('default', {});
    return true;
  }
  return false;
}

/**
 * Support TinyMCE in Turbolinks.
 *
 * Whenever a page loads, destroy old editors, then find and re-initialize any
 * new editors on the page. If the TinyMCE library has not yet loaded but is on
 * the new page, wait for it to load, then initialize.
 */
function handlePageLoad () {
  // Destroy any outgoing editors first.
  if (typeof tinymce !== 'undefined') {
    tinymce.remove();
  }

  // If editors can't be initialized, look for editor scripts and try to
  // initialize after they load (or fail).
  if (!initializeTinyMce()) {
    const tinyMceScripts = Array.from(document.querySelectorAll('script[src*="tinymce"]'));
    let remaining = tinyMceScripts.length;
    if (!remaining) return;

    function loadHandler (event) {
      event.target.removeEventListener('load', loadHandler, false);
      event.target.removeEventListener('error', loadHandler, false);
      remaining--;

      if (remaining <= 0) {
        initializeTinyMce()
      }
    }

    for (const script of tinyMceScripts) {
      script.addEventListener('load', loadHandler, false);
      script.addEventListener('error', loadHandler, false);
    }
  }
}

// Hook everything up!
if (typeof Turbolinks !== 'undefined' && Turbolinks.supported) {
  document.addEventListener('turbolinks:load', handlePageLoad);
} else {
  document.addEventListener('DOMContentLoaded', handlePageLoad);
}
